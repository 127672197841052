import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useTranslate from '@hooks/intl';
import {
  AppliedFilterType,
  CheckboxFilterConfig,
  DateRangeFilterConfig,
  DateType,
  RadioFilterConfig,
  Scalars,
  TableFilterConfigType,
  TableFilterTypes
} from '@types';
import { useTheme } from '@contexts/theme';
import Icon from '@components/icon/Icon';
import ClickOutside from '@utils/clickOutside';
import { dateFormatMonthShort } from '@utils/dateFormat';
import Button, { ButtonLabel } from '../button/Button';
import FilterChips from './components/FilterChips';
import FilterPopup from './components/FilterPopup';

interface FilterProps {
  handleApply: (appliedFilters: any) => void;
  filters: TableFilterConfigType;
  classNames?: Scalars['String'];
  showChips?: boolean;
  isSearchParamsRequired?: boolean;
  popupDirection?: Scalars['String'];
  appliedQueryParams?: any;
  applyDefaultFilter?: boolean;
}

const Filter: React.FC<FilterProps> = ({
  filters,
  classNames,
  popupDirection = 'right',
  isSearchParamsRequired = false,
  showChips = false,
  applyDefaultFilter = false,
  appliedQueryParams,
  handleApply,
  ...props
}) => {
  const { colors } = useTheme();
  const translate = useTranslate();
  const filterPopupRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [searchParams, setSearchParams] = useSearchParams();
  const initConfig = JSON.stringify(filters);
  const [isFilterHovered, setIsFilterHovered] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [inititalLoad, setInitialLoad] = useState(true);
  const [showFilterPopup, setShowFilterPopup] =
    useState<Scalars['Boolean']>(false);
  const [selectedFilterChips, setSelectedFilterChips] = useState([]);
  const [filterState, setFilterState] = useState<TableFilterConfigType>(
    JSON.parse(initConfig)
  );
  const [selectedFilterKey, setSelectedFilterKey] = useState(
    Object.keys(filterState)[0]
  );

  const getIconColor = (strokeColor: boolean = false): string => {
    if (isFilterApplied) {
      return isFilterHovered ? colors.primary : 'black';
    }
    if (isFilterHovered) return strokeColor ? colors.primary : 'white';
    return strokeColor ? colors.background50 : 'white';
  };

  const toggleFilterPopup = () => {
    setShowFilterPopup(!showFilterPopup);
  };

  const updateFilterState = (FilterState: TableFilterConfigType) => {
    setFilterState(FilterState);
  };

  const getAppliedFilters = (
    filterStateAfterSelection: TableFilterConfigType
  ) => {
    const appliedFilters: AppliedFilterType = {};
    let chips: any = [];
    Object.keys(filterStateAfterSelection).forEach((filterKey) => {
      switch (filterStateAfterSelection[filterKey].type) {
        case TableFilterTypes.CHECKBOX:
          {
            const { options, key, type } = filterStateAfterSelection[
              filterKey
            ] as CheckboxFilterConfig;
            const selectedValues = options
              ?.filter((item) => item.selected === true)
              .map((option) => option.value);
            if (selectedValues?.length) {
              appliedFilters[key] = selectedValues;
              const selectedChips: any = options
                ?.filter((option) => option.selected)
                .map((selectedOption) => ({
                  key,
                  name: selectedOption.name,
                  type
                }));
              chips = [...chips, ...selectedChips];
            }
          }
          break;
        case TableFilterTypes.RADIO:
          {
            const { options, key, type } = filterStateAfterSelection[
              filterKey
            ] as RadioFilterConfig;
            const selectedValue = options?.find(
              (option) => option.selected === true
            )?.value;
            if (selectedValue) {
              appliedFilters[key] = selectedValue;
              const selectedChips = options
                .filter((option) => option.selected === true)
                .map((selectedOption) => ({
                  key,
                  name: selectedOption.name,
                  type
                }));

              chips = [...chips, ...selectedChips];
            }
          }
          break;
        case TableFilterTypes.DATE_RANGE:
          {
            const { key, type, name, fromDate, toDate, isDateSelected } =
              filterStateAfterSelection[filterKey] as DateRangeFilterConfig;
            const fromDateValue = isDateSelected
              ? new Date(fromDate?.value).toISOString()
              : '';
            const toDateD = new Date(toDate?.value);
            toDateD.setHours(23);
            toDateD.setMinutes(59);
            toDateD.setSeconds(59);
            toDateD.setMilliseconds(999);
            const toDateValue = isDateSelected ? toDateD.toISOString() : '';
            if (isDateSelected) {
              appliedFilters[fromDate.key] = fromDateValue;
              appliedFilters[toDate.key] = toDateValue;
              const value = `${name.replace(
                'Date',
                ''
              )}: ${dateFormatMonthShort(
                new Date(fromDate?.value)
              )} - ${dateFormatMonthShort(toDateD)}`;
              chips = [...chips, { key, name: value, type }];
            }
          }
          break;
        case TableFilterTypes.DATE_PICKER:
          {
            const { key, type, name, fromDate, toDate, isDateSelected } =
              filterStateAfterSelection[filterKey] as DateRangeFilterConfig;
            const fromDateValue = isDateSelected
              ? new Date(fromDate?.value).toISOString()
              : '';
            const newToDate = new Date(toDate?.value);
            newToDate.setHours(23);
            newToDate.setMinutes(59);
            newToDate.setSeconds(59);
            newToDate.setMilliseconds(999);
            const toDateValue = isDateSelected ? newToDate.toISOString() : '';
            if (isDateSelected) {
              appliedFilters[fromDate.key] = fromDateValue;
              appliedFilters[toDate.key] = toDateValue;
              const value = `${name.replace(
                'Date',
                ''
              )}: ${dateFormatMonthShort(new Date(fromDate?.value))}`;
              chips = [...chips, { key, name: value, type }];
            }
          }
          break;
        default:
          break;
      }
    });
    setSelectedFilterChips(chips);
    return appliedFilters;
  };

  const onSubmit = (appFilterState = filterState) => {
    setInitialLoad(false);
    const appliedFilters = getAppliedFilters(appFilterState);
    const filterParams: AppliedFilterType = {};

    Object.keys(appliedFilters).forEach((key) => {
      filterParams[key] =
        typeof appliedFilters[key] === 'string'
          ? appliedFilters[key]
          : appliedFilters[key]?.join(',');
    });

    if (isSearchParamsRequired) {
      setSearchParams(filterParams);
    }
    handleApply(filterParams);
    setShowFilterPopup(false);
  };

  const initializeFilters = () => {
    let isFilterAppliedTemp = false;
    const filterStateClone: TableFilterConfigType = JSON.parse(initConfig);
    Object.keys(filterStateClone).forEach((key) => {
      switch (filterStateClone[key].type) {
        case TableFilterTypes.CHECKBOX:
          {
            const selectedOptionsArray = isSearchParamsRequired
              ? searchParams.get(key)?.split(',')
              : appliedQueryParams[key]?.split(',');
            if (selectedOptionsArray?.length) {
              isFilterAppliedTemp = true;
              (filterStateClone[key] as CheckboxFilterConfig).options?.forEach(
                (option) => {
                  option.selected = selectedOptionsArray.includes(option.value);
                }
              );
              (
                filterStateClone[key] as CheckboxFilterConfig
              ).selectedOptionsCount = selectedOptionsArray?.length;
            }
          }
          break;
        case TableFilterTypes.DATE_RANGE:
        case TableFilterTypes.DATE_PICKER:
          {
            const { fromDate, toDate } = {
              ...filterStateClone[key]
            } as DateRangeFilterConfig;
            const fromDateParam = searchParams.get(fromDate.key);
            const toDateParam = searchParams.get(toDate?.key);

            if (fromDateParam && toDateParam) {
              isFilterAppliedTemp = true;
              filterStateClone[key] = {
                ...filterStateClone[key],
                isDateSelected: true,
                fromDate: {
                  ...fromDate,
                  value: new Date(fromDateParam)
                },
                toDate: {
                  ...toDate,
                  value: new Date(toDateParam)
                }
              };
            } else if (
              !isSearchParamsRequired &&
              appliedQueryParams.fromDate &&
              appliedQueryParams.toDate
            ) {
              filterStateClone[key] = {
                ...filterStateClone[key],
                isDateSelected: true,
                fromDate: {
                  ...fromDate,
                  value: appliedQueryParams.fromDate
                },
                toDate: {
                  ...toDate,
                  value: appliedQueryParams.toDate
                }
              };
            }
          }
          break;
        case TableFilterTypes.RADIO:
          {
            const selectedOption = isSearchParamsRequired
              ? searchParams.get(key)
              : appliedQueryParams[key];
            if (selectedOption) {
              isFilterAppliedTemp = true;
              (filterStateClone[key] as RadioFilterConfig).options?.forEach(
                (option) => {
                  option.selected = option.value === selectedOption;
                }
              );
            }
          }
          break;
        default:
          break;
      }
    });

    if (inititalLoad && !isFilterAppliedTemp && applyDefaultFilter) {
      Object.keys(filterStateClone).forEach((key) => {
        if ((filterStateClone[key] as DateRangeFilterConfig)?.defaultFilter) {
          switch (filterStateClone[key].type) {
            case TableFilterTypes.DATE_RANGE:
            case TableFilterTypes.DATE_PICKER:
              {
                const fromDate = (
                  filterStateClone[key] as DateRangeFilterConfig
                )?.defaultFilter?.fromDate;
                const toDate = (filterStateClone[key] as DateRangeFilterConfig)
                  ?.defaultFilter?.toDate;
                const toDateD = new Date(toDate?.value);
                toDateD.setHours(23);
                toDateD.setMinutes(59);
                toDateD.setSeconds(59);
                toDateD.setMilliseconds(999);
                setFilterApplied(true);
                filterStateClone[key] = {
                  ...filterStateClone[key],
                  isDateSelected: true,
                  fromDate: {
                    ...fromDate,
                    value: new Date(fromDate?.value)
                  } as DateType,
                  toDate: {
                    ...toDate,
                    value: toDateD
                  } as DateType
                };
              }
              break;
            case TableFilterTypes.CHECKBOX:
              {
                const defaultFilterValues = (
                  filterStateClone[key] as CheckboxFilterConfig
                ).defaultFilter;

                if (defaultFilterValues?.length) {
                  (
                    filterStateClone[key] as CheckboxFilterConfig
                  ).options?.forEach((option) => {
                    option.selected = defaultFilterValues.includes(
                      option.value
                    );
                  });
                  (
                    filterStateClone[key] as CheckboxFilterConfig
                  ).selectedOptionsCount = defaultFilterValues?.length;
                }
              }
              break;
            case TableFilterTypes.RADIO:
              {
                const defaultFilterValue = (
                  filterStateClone[key] as RadioFilterConfig
                ).defaultFilter;

                if (defaultFilterValue) {
                  (filterStateClone[key] as RadioFilterConfig).options?.forEach(
                    (option) => {
                      option.selected = option.value === defaultFilterValue;
                    }
                  );
                }
              }
              break;
            default:
              break;
          }
        }
      });
    }
    updateFilterState({ ...filterStateClone });
    setSelectedFilterKey(Object.keys(filterStateClone)[0]);
    if (inititalLoad) onSubmit({ ...filterStateClone });
  };

  const clearFilters = () => {
    const initialFilterState: TableFilterConfigType = JSON.parse(initConfig);
    updateFilterState(initialFilterState);
    setSelectedFilterKey(Object.keys(filterState)[0]);
  };

  const removeFilter = (filter: any) => {
    switch (filter.type) {
      case TableFilterTypes.CHECKBOX:
      case TableFilterTypes.RADIO:
        {
          const { options } = filterState[filter.key] as CheckboxFilterConfig;
          options?.forEach((option) => {
            if (option.name === filter.name) option.selected = false;
          });
        }
        break;
      case TableFilterTypes.DATE_RANGE:
        {
          const dateFilter = filterState[filter.key] as DateRangeFilterConfig;
          dateFilter.isDateSelected = false;
        }
        break;
      default:
        break;
    }
    onSubmit({ ...filterState });
  };

  const resetFilters = () => {
    setFilterApplied(false);
    const filterStateClone: TableFilterConfigType = JSON.parse(initConfig);
    updateFilterState(filterStateClone);
    onSubmit(filterStateClone);
  };

  useEffect(() => {
    initializeFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFilterPopup]);

  useEffect(() => {
    if (!selectedFilterChips?.length) {
      setFilterApplied(false);
    } else {
      setFilterApplied(true);
    }
  }, [selectedFilterChips]);

  ClickOutside({
    ref: filterPopupRef,
    handler: () => {
      if (showFilterPopup) toggleFilterPopup();
    }
  });

  return (
    <>
      <div className={`relative ${classNames}`} ref={filterPopupRef} {...props}>
        <Button
          variant="outline"
          handleClick={toggleFilterPopup}
          className={`group/filter flex h-10 w-24 items-center justify-center !border-background10 px-4 py-2 hover:!border-primary hover:!bg-white
              ${isFilterApplied ? '!border-primary' : ''}`}
          size="small"
          onHover={(isHovered: boolean) => setIsFilterHovered(isHovered)}
        >
          <ButtonLabel
            variant="outline"
            label={translate('component.filter.title')}
            className="text-body group-hover/filter:text-primary pr-2 text-background90"
          />
          <Icon
            name="filter"
            id="icon1"
            fill={getIconColor()}
            stroke={getIconColor(true)}
            size="small"
            className="pt-0.5"
          />
        </Button>
        {showFilterPopup && (
          <FilterPopup
            filterState={filterState}
            selectedFilterKey={selectedFilterKey}
            setSelectedFilterKey={setSelectedFilterKey}
            clearFilters={clearFilters}
            handleApply={onSubmit}
            updateFilterState={updateFilterState}
            popupDirection={popupDirection}
          />
        )}
      </div>
      {isFilterApplied && showChips && (
        <FilterChips
          resetFilters={resetFilters}
          onRemoveFilter={removeFilter}
          selectedFilters={selectedFilterChips}
          toggleFilterPopup={toggleFilterPopup}
        />
      )}
    </>
  );
};

export default Filter;
